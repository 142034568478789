import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/partials/seo'
import Layout from '../components/partials/layout'
import Header from '../components/home/header'
import People from '../components/home/people'

class ColleaguesIndex extends React.Component {
  state = { shuffled: [] }

  componentDidMount() {
    const people = get(this, 'props.data.allContentfulPerson.nodes')
    // Shuffle array
    const shuffled = people.sort(() => 0.5 - Math.random())
    // Get sub-array of first n elements after shuffled
    this.setState({ shuffled })
  }

  render() {
    const cookies = get(this, 'props.data.contentfulCookies')
    const menuItems = get(this, 'props.data.allContentfulMenuItem.nodes')
    const headerImage = get(this, 'props.data.contentfulAsset')
    return (
      <Layout
        location={this.props.location}
        menuItems={menuItems}
        cookies={cookies}
      >
        <Header gatsbyImage={headerImage} />
        <Seo />
        <People peeps={this.state.shuffled} url={null} />
      </Layout>
    )
  }
}

export default ColleaguesIndex

export const pageQuery = graphql`
  query ColleaguesQuery($locale: String!) {
    contentfulCookies {
      linkName
      linkUrl
      title
      content
    }
    allContentfulMenuItem(sort: { fields: [order] }) {
      nodes {
        node_locale
        code
        name
        url
      }
    }
    contentfulAsset(title: { eq: "colleagues" }) {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
        width: 5946
        height: 3969
      )
    }
    allContentfulPerson(
      filter: { companies: { in: [$locale] }, node_locale: { eq: $locale } }
    ) {
      nodes {
        name
        title
        shortBio {
          shortBio
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 240
            height: 240
          )
        }
        tags
        linkedInUrl
      }
    }
  }
`
